import { autoUpdate, flip, FloatingPortal, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useMergeRefs, useRole } from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import * as React from 'react';
import { Content } from '@Components/atoms/Tooltip/Tooltip.styles';
interface TooltipOptions {
  placement?: Placement;
  offsetValue?: number;
  showOnFocus?: boolean;
  onOpenChange?: (open: boolean) => void;
}
export function useTooltip({
  placement = 'top',
  offsetValue = 5,
  showOnFocus = true,
  onOpenChange
} = {}) {
  const [open, setOpen] = React.useState(false);
  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (onOpenChange) {
      onOpenChange(open);
    }
  };
  const data = useFloating({
    placement,
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [offset(offsetValue), flip({
      crossAxis: placement.includes('-'),
      fallbackAxisSideDirection: 'start',
      padding: 5
    }), shift({
      padding: 5
    })]
  });
  const context = data.context;
  const hover = useHover(context, {
    move: false,
    enabled: true
  });
  const focus = useFocus(context, {
    enabled: showOnFocus
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'tooltip'
  });
  const interactions = useInteractions([hover, focus, dismiss, role]);
  return React.useMemo(() => ({
    open,
    setOpen,
    ...interactions,
    ...data
  }), [open, setOpen, interactions, data]);
}
type ContextType = ReturnType<typeof useTooltip> | null;
const TooltipContext = React.createContext<ContextType>(null);
export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);
  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }
  return context;
};
export function Tooltip({
  children,
  ...options
}: {
  children: React.ReactNode;
} & TooltipOptions) {
  const tooltip = useTooltip(options);
  return <TooltipContext.Provider value={tooltip} data-sentry-element="unknown" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">{children}</TooltipContext.Provider>;
}
export const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & {
  asChild?: boolean;
}>(function TooltipTrigger({
  children,
  asChild = false,
  ...props
}, propRef) {
  const context = useTooltipContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(children, context.getReferenceProps({
      ref,
      ...props,
      ...(children.props ?? {})
    }));
  }
  return <span ref={ref} {...context.getReferenceProps(props)}>
        {children}
      </span>;
});
export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & {
  maxWidth?: number;
  isSmall?: boolean;
}>(function TooltipContent({
  style,
  maxWidth,
  isSmall,
  ...props
}, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  if (!context.open) {
    return null;
  }
  return <FloatingPortal>
      <Content ref={ref} maxWidth={maxWidth} style={{
      ...context.floatingStyles,
      ...style,
      ...(isSmall ? {
        padding: '0.25rem 0.5rem'
      } : {})
    }} {...context.getFloatingProps(props)} />
    </FloatingPortal>;
});